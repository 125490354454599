import HeaderContainer_DefaultWithFillLayersComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/DefaultWithFillLayers/DefaultWithFillLayers.skin';
import HeaderContainer_DefaultWithFillLayersController from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/HeaderContainer.controller';


const HeaderContainer_DefaultWithFillLayers = {
  component: HeaderContainer_DefaultWithFillLayersComponent,
  controller: HeaderContainer_DefaultWithFillLayersController
};


export const components = {
  ['HeaderContainer_DefaultWithFillLayers']: HeaderContainer_DefaultWithFillLayers
};

